// 表单属性【右面板】
export const formConf = {
        formRef: "elForm", //表单名
        formModel: "formData", //表单模型
        size: "medium", //表单尺寸
        labelPosition: "top", //标签对齐方式
        labelWidth: 100, //标签宽度
        formRules: "rules", //校验模型
        gutter: 15, //栅格间隔
        disabled: false, //禁用表单
        span: 24,
        formBtns: true //表单按钮
    }
    // 输入型组件 【左面板】
export const inputComponents = [{
        //组件的自定义配置
        __config__: {
            label: "问答题", //表单标题
            labelWidth: null, //表单标题区域宽度 px
            showLabel: true, //是否显示表单标题
            tag: "el-input", //组件名称
            tagIcon: "textarea", //组件svg图标名称
            defaultValue: undefined, //默认值；与__vModel__对应使用,可指定表单字段的默认值；可用于表单数据回填
            required: true, //是否要求表单校验
            layout: "colFormItem", //组件使用的布局方式
            span: 24, //24栅格系统，表示组件的栅格数
            regList: [], //表单正则校验
            changeTag: false, //是否允许显示切换组件面板
            document: "https://element.eleme.cn/#/zh-CN/component/input", //组件说明文档地址
            scoreValue: "", //分值
            resolve: "", //解析
            power: "" //能力标准
        },
        //可直接写在组件标签上的属性
        type: "textarea",
        // placeholder: "请输入",
        autosize: {
            minRows: 4,
            maxRows: 4
        },
        style: { width: "100%" },
        maxlength: null,
        "show-word-limit": false,
        readonly: false,
        disabled: false
    },
    {
        __config__: {
            label: "作业说明",
            showLabel: true,
            changeTag: false,
            labelWidth: null,
            tag: "tinymce",
            tagIcon: "rich-text",
            defaultValue: null,
            span: 24,
            layout: "colFormItem",
            required: true,
            regList: [],
            document: "http://tinymce.ax-z.cn"
        },
        placeholder: "请输入作业说明",
        height: 300, // 编辑器高度
        branding: false // 隐藏右下角品牌烙印
    }
]

// 选择型组件 【左面板】
export const selectComponents = [{
        __config__: {
            label: "判断题",
            labelWidth: null,
            showLabel: true,
            tag: "el-radio-group",
            tagIcon: "radio",
            changeTag: true,
            defaultValue: undefined,
            layout: "colFormItem",
            span: 24,
            optionType: "default",
            regList: [],
            required: true,
            border: false,
            document: "https://element.eleme.cn/#/zh-CN/component/radio",
            scoreValue: "",
            resolve: "",
            power: "" //能力标准
        },

        __slot__: {
            options: [{
                    label: "正确",
                    value: "正确"
                },
                {
                    label: "错误",
                    value: "错误"
                }
            ]
        },
        style: {},
        size: "medium",
        disabled: false
    },
    {
        __config__: {
            label: "单选题",
            labelWidth: null,
            showLabel: true,
            tag: "el-radio-group",
            tagIcon: "radio",
            changeTag: true,
            defaultValue: undefined,
            layout: "colFormItem",
            span: 24,
            optionType: "default",
            regList: [],
            required: true,
            border: false,
            document: "https://element.eleme.cn/#/zh-CN/component/radio"
        },
        //组件的插槽属性
        __slot__: {
            options: [{
                    label: "",
                    value: "A"
                },
                {
                    label: "",
                    value: "B"
                },
                {
                    label: "",
                    value: "C"
                },
                {
                    label: "",
                    value: "D"
                }
            ]
        },
        style: {},
        size: "medium",
        disabled: false,
        scoreValue: "",
        resolve: "",
        power: "" //能力标准
    },
    {
        __config__: {
            label: "多选题",
            tag: "el-checkbox-group",
            tagIcon: "checkbox",
            defaultValue: [],
            span: 24,
            showLabel: true,
            labelWidth: null,
            layout: "colFormItem",
            optionType: "default",
            required: true,
            regList: [],
            changeTag: true,
            border: false,
            document: "https://element.eleme.cn/#/zh-CN/component/checkbox"
        },
        __slot__: {
            options: [{
                    label: "",
                    value: "A"
                },
                {
                    label: "",
                    value: "B"
                },
                {
                    label: "",
                    value: "C"
                },
                {
                    label: "",
                    value: "D"
                }
            ]
        },
        style: {},
        size: "medium",
        min: null,
        max: null,
        disabled: false,
        scoreValue: "",
        resolve: "",
        power: "" //能力标准
    },
    {
        __config__: {
            label: "附件题",
            tag: "el-upload",
            tagIcon: "upload",
            layout: "colFormItem",
            defaultValue: null,
            showLabel: true,
            labelWidth: null,
            required: true,
            span: 24,
            showTip: false,
            buttonText: "点击上传",
            regList: [],
            changeTag: true,
            fileSize: 2,
            sizeUnit: "MB",
            document: "https://element.eleme.cn/#/zh-CN/component/upload",
            // videolist:[],
            // audiolist:[],
            // piclist:[]
        },
        __slot__: {
            "list-type": true
        },
        action: "https://jsonplaceholder.typicode.com/posts/",
        disabled: false,
        accept: "",
        name: "file",
        "auto-upload": true,
        "list-type": "text",
        multiple: false,
        scoreValue: ""
    }
]

// 布局型组件 【左面板】
export const layoutComponents = []