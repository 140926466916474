<template>
  <div class="work_wrap">
    <div class="nav"></div>
    <div class="container">
      <!-- 页面左侧 -->
      <div class="left-board">
        <!-- 左侧表单组件选项 -->
        <el-scrollbar class="left-scrollbar">
          <div class="components-list">
            <div v-for="(item, listIndex) in leftComponents" :key="listIndex">
              <div class="components-title">
                <svg-icon icon-class="component" />
                {{ item.title }}
              </div>
              <draggable class="components-draggable" :list="item.list"
                :group="{ name: 'componentsGroup', pull: 'clone', put: false }" :clone="cloneComponent"
                draggable=".components-item" :sort="false" @end="onEnd">
                <div v-for="(element, index) in item.list" :key="index" class="components-item"
                  @click="addComponent(element)">
                  <div class="components-body">
                    <svg-icon :icon-class="element.__config__.tagIcon" />
                    {{ element.__config__.label }}
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <!-- 中部生成的表单 -->
      <div class="center-board">
        <!-- 中部顶部的button -->
        <div class="action-bar">
          <div class="save" @click="subAgain">保存并预览</div>
          <el-button class="delete-btn" icon="el-icon-delete" type="text" @click="empty">清空</el-button>
        </div>
        <!-- 生成的表单 -->
        <el-scrollbar class="center-scrollbar">
          <el-row class="center-board-row" :gutter="formConf.gutter">
            <el-form :size="formConf.size" :label-position="formConf.labelPosition" :disabled="formConf.disabled"
              :label-width="formConf.labelWidth + 'px'">
              <draggable class="drawing-board" :list="drawingList" :animation="340" group="componentsGroup">
                <draggable-item v-for="(item, index) in drawingList" :key="item.renderKey" :drawing-list="drawingList"
                  :current-item="item" :index="index" :active-id="activeId" :form-conf="formConf"
                  @activeItem="activeFormItem" @deleteItem="drawingItemDelete" />
              </draggable>
              <div v-show="!drawingList.length" class="empty-info">
                从左侧拖入或点选组件进行表单设计
              </div>
            </el-form>
          </el-row>
        </el-scrollbar>
      </div>
      <!-- 页面右侧组件 -->
      <right-panel :active-data="activeData" :form-conf="formConf" :show-field="!!drawingList.length"
        @tag-change="tagChange" ref="rightPanel" :workProp="$route.query.isCreate ? '' : workprop"
        @addPower="addPower" @addTemplate="addTemplate" />
      <input id="copyNode" type="hidden" />
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import {
    debounce
  } from "throttle-debounce";
  import {
    saveAs
  } from "file-saver";
  import ClipboardJS from "clipboard";
  import render from "@/components/render/render";
  import JsonDrawer from "./JsonDrawer";
  import RightPanel from "./RightPanel";
  import {
    inputComponents,
    selectComponents,
    layoutComponents,
    formConf,
  } from "@/components/generator/config_edit";
  import {
    exportDefault,
    beautifierConf,
    isNumberStr,
    titleCase,
    deepClone,
  } from "@/utils/index";
  import {
    makeUpHtml,
    vueTemplate,
    vueScript,
    cssStyle,
  } from "@/components/generator/html";
  import {
    makeUpJs
  } from "@/components/generator/js";
  import {
    makeUpCss
  } from "@/components/generator/css";
  import drawingDefalut from "@/components/generator/drawingDefalut";
  import logo from "@/assets/logo.png";
  import CodeTypeDialog from "./CodeTypeDialog";
  import DraggableItem from "./DraggableItem";
  import {
    getDrawingList,
    saveDrawingList,
    getIdGlobal,
    saveIdGlobal,
    getFormConf,
  } from "@/utils/db";
  import loadBeautifier from "@/utils/loadBeautifier";

  let beautifier;
  const emptyActiveData = {
    style: {},
    autosize: {},
  };
  let oldActiveId;
  let tempActiveData;
  const drawingListInDB = getDrawingList();
  const formConfInDB = getFormConf();
  const idGlobal = getIdGlobal();

  export default {
    components: {
      draggable,
      render,
      JsonDrawer,
      RightPanel,
      CodeTypeDialog,
      DraggableItem,
    },
    data() {
      return {
        logo,
        idGlobal,
        formConf,
        inputComponents,
        selectComponents,
        layoutComponents,
        labelWidth: 100,
        drawingList: drawingDefalut,
        drawingData: {},
        activeId: drawingDefalut[0].formId,
        drawerVisible: false,
        formData: {},
        dialogVisible: false,
        jsonDrawerVisible: false,
        generateConf: null,
        showFileName: false,
        activeData: drawingDefalut[0],
        saveDrawingListDebounce: debounce(340, saveDrawingList),
        saveIdGlobalDebounce: debounce(340, saveIdGlobal),
        leftComponents: [{
            title: "主观题",
            list: inputComponents,
          },
          {
            title: "客观题",
            list: selectComponents,
          },
        ],
        workprop: {}, //右侧自定义的作业属性
      };
    },
    computed: {},
    watch: {
      // eslint-disable-next-line func-names
      "activeData.__config__.label": function (val, oldVal) {
        if (
          this.activeData.placeholder === undefined ||
          !this.activeData.__config__.tag ||
          oldActiveId !== this.activeId
        ) {
          return;
        }
        this.activeData.placeholder =
          this.activeData.placeholder.replace(oldVal, "") + val;
      },
      activeId: {
        handler(val) {
          oldActiveId = val;
        },
        immediate: true,
      },
      drawingList: {
        handler(val) {
          this.saveDrawingListDebounce(val);
          if (val.length === 0) this.idGlobal = 100;
        },
        deep: true,
      },
      idGlobal: {
        handler(val) {
          this.saveIdGlobalDebounce(val);
        },
        immediate: true,
      },
    },
    mounted() {
       localStorage.removeItem("drawingItems");
      this.drawingList = [];
      console.log("编辑问卷mounted()");
      this.getForm(this.$route.query.workId);
      // this.getForm(-1);
      if (Array.isArray(drawingListInDB) && drawingListInDB.length > 0) {
        this.drawingList = drawingListInDB;
      } else {
        this.drawingList = drawingDefalut;
      }
      this.activeFormItem(this.drawingList[0]);
      if (formConfInDB) {
        this.formConf = formConfInDB;
      }
      loadBeautifier((btf) => {
        beautifier = btf;
      });
    },
    activated() {
      localStorage.removeItem("drawingItems");
      this.drawingList = [];
      console.log("编辑问卷activated()");
      this.getForm(this.$route.query.workId);
    },
    methods: {
      // 添加能力标准
      addPower(data) {
        console.log("子组件传递过来的能力标准--", data);
        this.$set(this.activeData.__config__, "power", data);
      },
      // 添加习题模板
      addTemplate(data){
        console.log("子组件传递过来的习题模板--", data);
        this.$set(this.activeData.__config__, "td", data);
      },
      //获取指定问卷(专家出题)
      getForm(workId) {
        // localStorage.removeItem("drawingItems");
        this.$Api.Form.getTeaForm(workId)
          .then((value) => {
            console.log(value);
            //回填上次编辑的作业属性数据
            this.workprop = JSON.parse(value.data.title);
            this.workprop.needStudy = value.data.needStudy;
            let workContent = JSON.parse(value.data.content);
            workContent.map((item) => {
              if (item.__config__.tag == "el-checkbox-group") {
                item.__config__.defaultValue = [];
              } else if (item.__config__.tag == "tinymce") {
                item.defaultValue = item.defaultValue;
              } else {
                item.__config__.defaultValue = "";
              }
            });
            let resultList = JSON.parse(value.data.answer);
            workContent.map((item) => {
              if (resultList.hasOwnProperty(item.__vModel__) == true) {
                item.__config__.defaultValue = resultList[item.__vModel__];
              }
            });
            localStorage.setItem("drawingItems", JSON.stringify(workContent));
            this.drawingList = JSON.parse(localStorage.getItem("drawingItems"));
          })
          .catch((reason) => {
            console.log(reason);
          });
      },

      activeFormItem(currentItem) {
        this.$refs.rightPanel.powerValue = []; //清空能力标准
        if (currentItem.__config__.power) {
          this.$refs.rightPanel.powerValue =
            currentItem.__config__.power[0].powerVal;
        }
        this.activeData = currentItem;
        this.activeId = currentItem.__config__.formId;
      },
      onEnd(obj) {
        if (obj.from !== obj.to) {
          this.activeData = tempActiveData;
          this.activeId = this.idGlobal;
        }
      },
      addComponent(item) {
        // console.log('点击左侧添加表单组件')
        console.log(item);
        const clone = this.cloneComponent(item);
        // console.log("新增前的drawingList--",this.drawingList)
        // console.log('编辑页面新增题目',clone)
        let arr = [];
        this.drawingList
          .filter((item) => {
            return item.__vModel__ != "explain";
          })
          .map((item) => {
            arr.push(parseInt(item.__vModel__.substring(5)));
            // if(item.__vModel__ == clone.__vModel__){
            //   // console.log(clone.__vModel__.substring(5),parseInt(clone.__vModel__.substring(5))+1,"field"+(parseInt(clone.__vModel__.substring(5))+1))
            //   console.log("field"+(parseInt(clone.__vModel__.substring(5))+1))
            // }
          });
        // console.log(arr)
        var max = Math.max.apply(null, arr);
        // console.log(max)
        this.drawingList.map((item) => {
          if (item.__vModel__ == clone.__vModel__) {
            clone.__vModel__ = "field" + (max + 1);
            this.drawingList.push(clone);
            this.activeFormItem(clone);
          }
        });
        // console.log(clone)

        console.log("新增后的drawingList--", this.drawingList);
      },
      cloneComponent(origin) {
        const clone = deepClone(origin);
        const config = clone.__config__;
        config.span = this.formConf.span; // 生成代码时，会根据span做精简判断
        this.createIdAndKey(clone);
        clone.placeholder !== undefined && (clone.placeholder += config.label);
        tempActiveData = clone;
        return tempActiveData;
      },
      createIdAndKey(item) {
        const config = item.__config__;
        config.formId = ++this.idGlobal;
        config.renderKey = `${config.formId}${+new Date()}`; // 改变renderKey后可以实现强制更新组件
        if (config.layout === "colFormItem") {
          item.__vModel__ = `field${this.idGlobal}`;
        } else if (config.layout === "rowFormItem") {
          config.componentName = `row${this.idGlobal}`;
          !Array.isArray(config.children) && (config.children = []);
          delete config.label; // rowFormItem无需配置label属性
        }
        if (Array.isArray(config.children)) {
          config.children = config.children.map((childItem) =>
            this.createIdAndKey(childItem)
          );
        }
        return item;
      },
      AssembleFormData() {
        this.formData = {
          fields: deepClone(this.drawingList),
          ...this.formConf,
        };
      },
      generate(data) {
        const func = this[`exec${titleCase(this.operationType)}`];
        this.generateConf = data;
        func && func(data);
      },
      execRun(data) {
        this.AssembleFormData();
        this.drawerVisible = true;
      },
      execDownload(data) {
        const codeStr = this.generateCode();
        const blob = new Blob([codeStr], {
          type: "text/plain;charset=utf-8",
        });
        saveAs(blob, data.fileName);
      },
      execCopy(data) {
        document.getElementById("copyNode").click();
      },

      subAgain() {
        if (this.$route.query.themetype == 4) {
          this.createspeak()
        } else {
        // console.log(workconfig);
        if (!this.$refs.rightPanel.workname.trim()) {
          this.$message({
            message: "请填写作业名称",
            type: "warning",
          });
        } else if (!this.$refs.rightPanel.beginTime) {
          this.$message({
            message: "请选择作业开始时间",
            type: "warning",
          });
        } else if (!this.$refs.rightPanel.endTime) {
          this.$message({
            message: "请选择作业结束时间",
            type: "warning",
          });
        } else if (
          !this.compareTime(
            this.$refs.rightPanel.beginTime,
            this.$refs.rightPanel.endTime
          )
        ) {
          this.$message({
            message: "作业结束时间应大于开始时间",
            type: "warning",
          });
        } else if (this.drawingList.length <= 1) {
          this.$message({
            message: "作业内容不能为空",
            type: "warning",
          });
        } else if (!this.checktitle()) {
          this.$message({
            message: "题目不能为空",
            type: "warning",
          });
        } else if (
          this.$route.query.themebind == 1 &&
          !this.$refs.rightPanel.courseid
        ) {
          this.$message({
            message: "请选择课节",
            type: "warning",
          });
        } else {
          this.$confirm("是否将此问卷提交到服务器？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "info",
            })
            .then(() => {
              let workconfig = {
                topicType: this.$refs.rightPanel.topicType,
                topicname: this.$refs.rightPanel.topicname, //主题名称
                subjectValue: this.$refs.rightPanel.subjectValue, //绑定学科默认选中
                topicBind: this.$refs.rightPanel.topicBind, //主题绑定默认选中
                courseValue: this.$refs.rightPanel.courseValue, //绑定课程默认选中
                coverUrl: require("@/assets/cover.png"),
                //创建作业自定义表单
                workname: this.$refs.rightPanel.workname, //名称
                userValue: this.$refs.rightPanel.userValue,
                beginTime: this.$refs.rightPanel.beginTime, //开始时间
                endTime: this.$refs.rightPanel.endTime, //结束时间
                workType: this.$refs.rightPanel.workType, //作业类型默认选中
                isSettime: this.$refs.rightPanel.isSettime, //是否设置时间
                setTime: this.$refs.rightPanel.setTime, //设置时间默认值
                // ruleList: this.$refs.rightPanel.ruleList, //提交规则
                edit: this.$refs.rightPanel.edit, //是否可以二次编辑
                isLook: this.$refs.rightPanel.isLook, //始终不可查看他人提交的内容
                supportCopy: this.$refs.rightPanel.supportCopy, //课程内容是否支持复制
                checkCommit: this.$refs.rightPanel.checkcommit, //教师是否查看到学生提交的作业
                isCheck: this.$refs.rightPanel.isCheck, //教师是否可以进行批改
                score: this.$refs.rightPanel.score, //分值
                fine: this.$refs.rightPanel.fine, //学员端展示
                resolve: this.$refs.rightPanel.resolve, //答案解析
                // power: this.$refs.rightPanel.power,//能力标准
                lessonId: this.$refs.rightPanel.courseid,
                subagain: this.$refs.rightPanel.subagain,
                // subStart: this.$refs.rightPanel.subAgainStart,
                // subEnd: this.$refs.rightPanel.subAgainEnd,
              };
              console.log(workconfig);
              let sratrArr,endArr
              if (this.$refs.rightPanel.subagain) {
                workconfig.subStart = this.$refs.rightPanel.subAgainStart;
                workconfig.subEnd = this.$refs.rightPanel.subAgainEnd;
                sratrArr = this.$refs.rightPanel.subAgainStart.split('-')
                endArr = this.$refs.rightPanel.subAgainEnd.split('-')
              }
              // console.log("提交参数---", params);
              // 判断是创建还是编辑
              if (this.$route.query.isCreate) {
                let params = {
                  createrId: this.$store.state.user.userInfo.id, //创建者id
                  content: localStorage.getItem("drawingItems"),
                  title: JSON.stringify(workconfig),
                  themeId: this.$route.query.themeId,
                  needStudy: this.$refs.rightPanel.needStudy ? 1 : 0,
                  supplement: this.$refs.rightPanel.subagain ? 1 : 0
                };
                if (this.$refs.rightPanel.subagain) {
                  params.supplementStart = sratrArr[0] + '-' + sratrArr[1] + '-' + sratrArr[2] + ' ' + sratrArr[3]
                  params.supplementEnd = endArr[0] + '-' + endArr[1] + '-' + endArr[2] + ' ' + endArr[3]
                }
                console.log('提交参数--',params)
                this.$Api.Form.addForm(params)
                  .then((res) => {
                    // console.log(res);
                    this.$message({
                      type: "success",
                      message: "提交成功",
                    });
                    //提交作业并跳转至作业管理页面查看
                    this.$router.push({
                      path: "/workManage",
                      query: {
                        themeId: this.$route.query.themeId,
                      },
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                    this.$message({
                      type: "error",
                      message: err,
                    });
                  });
              } else {
                let params = {
                  createrId: this.$route.query.createId,
                  content: localStorage.getItem("drawingItems"),
                  title: JSON.stringify(workconfig),
                  id: this.$route.query.workId,
                  mdQuestionnaireId: this.$route.query.mdQuestionnaireId,
                  needStudy: this.$refs.rightPanel.needStudy ? 1 : 0,
                  supplement: this.$refs.rightPanel.subagain ? 1 : 0
                };
                if (this.$refs.rightPanel.subagain) {
                  params.supplementStart = sratrArr[0] + '-' + sratrArr[1] + '-' + sratrArr[2] + ' ' + sratrArr[3]
                  params.supplementEnd = endArr[0] + '-' + endArr[1] + '-' + endArr[2] + ' ' + endArr[3]
                }
                this.$Api.Form.addAgainForm(params)
                  .then((res) => {
                    // console.log(res);
                    this.$message({
                      type: "success",
                      message: "提交成功",
                    });
                    this.$router.push({
                      path: "/workManage",
                      query: {
                        themeId: this.$route.query.themeId,
                      },
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                    this.$message({
                      type: "error",
                      message: err,
                    });
                  });
              }
            })
            .catch((err) => {
              console.log(err)
              this.$message({
                type: "info",
                message: "取消成功",
              });
            });
        }
        }
      },
            //创建普通话作业
      createspeak() {
        if (!this.$refs.rightPanel.workname.trim()) {
          this.$message({
            message: '请填写作业名称',
            type: 'warning'
          });
        } else if (!this.$refs.rightPanel.beginTime) {
          this.$message({
            message: '请选择作业开始时间',
            type: 'warning'
          });
        } else if (!this.$refs.rightPanel.endTime) {
          this.$message({
            message: '请选择作业结束时间',
            type: 'warning'
          });
        } else if (!this.compareTime(this.$refs.rightPanel.beginTime, this.$refs.rightPanel.endTime)) {
          this.$message({
            message: '作业结束时间应大于开始时间',
            type: 'warning'
          });
        } else if (!this.$refs.rightPanel.repository) {
          this.$message({
            message: '请选择试题库',
            type: 'warning'
          });
        } else {
          this.$confirm("是否将此问卷提交到服务器？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "info",
            })
            .then(() => {
              let workconfig = {
                //创建作业自定义表单
                workname: this.$refs.rightPanel.workname, //名称
                userValue: this.$refs.rightPanel.userValue,
                beginTime: this.$refs.rightPanel.beginTime, //开始时间
                endTime: this.$refs.rightPanel.endTime, //结束时间
                isSettime: this.$refs.rightPanel.isSettime, //是否设置时间
                setTime: this.$refs.rightPanel.setTime, //设置时间默认值
                edit: this.$refs.rightPanel.edit, //是否可以二次编辑
                isLook: this.$refs.rightPanel.isLook, //始终不可查看他人提交的内容
                supportCopy: this.$refs.rightPanel.supportCopy,
                checkCommit: this.$refs.rightPanel.checkcommit,
                isCheck: this.$refs.rightPanel.isCheck,
                score: this.$refs.rightPanel.score,
                fine: this.$refs.rightPanel.fine,
                resolve: this.$refs.rightPanel.resolve,
              };
              let params = {
                // createrId: JSON.parse(sessionStorage.getItem("userinfo")).id, //创建者id
                createrId: this.$store.state.user.userInfo.id, //创建者id
                content: localStorage.getItem("drawingItems"),
                title: JSON.stringify(workconfig),
                themeId: this.$route.query.themeId,
                mdQuestionnaireId: this.$refs.rightPanel.repository
              };
              console.log("提交参数---", params);
              this.$Api.Form.addForm(params)
                .then((res) => {
                  // console.log(res);
                  this.$message({
                    type: "success",
                    message: "提交成功",
                  });
                  //提交作业并跳转至作业管理页面查看
                  this.$router.push({
                    path: "/workManage",
                    query: {
                      themeId: this.$route.query.themeId,
                      themetype: this.$route.query.themetype
                    },
                  });
                })
                .catch((err) => {
                  console.log(err);
                  this.$message({
                    type: "error",
                    message: err,
                  });
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消成功",
              });
            });
        }
      },
      //判断问卷是否都填写题目
      checktitle() {
        let hastitle = true;
        try {
          this.drawingList.forEach((item) => {
            if (!item.__config__.label) {
              hastitle = false;
              throw new Error("检查到有题目为空");
            }
          });
        } catch (err) {
          console.log(err);
        }
        return hastitle;
      },
      //选择时间对比
      compareTime(begintime, endtime) {
        let btime = begintime.split("");
        btime.splice(10, 1, " ");
        let etime = endtime.split("");
        etime.splice(10, 1, " ");
        if (
          new Date(etime.join("")).getTime() <= new Date(btime.join("")).getTime()
        ) {
          return false;
        } else {
          return true;
        }
      },

      empty() {
        this.$confirm("确定要清空所有组件吗？", "提示", {
          type: "warning",
        }).then(() => {
          this.drawingList = [];
          this.idGlobal = 100;
        });
      },

      drawingItemDelete(index, list) {
        list.splice(index, 1);
        this.$nextTick(() => {
          const len = this.drawingList.length;
          if (len) {
            this.activeFormItem(this.drawingList[len - 1]);
          }
        });
      },
      generateCode() {
        const {
          type
        } = this.generateConf;
        this.AssembleFormData();
        const script = vueScript(makeUpJs(this.formData, type));
        const html = vueTemplate(makeUpHtml(this.formData, type));
        const css = cssStyle(makeUpCss(this.formData));
        return beautifier.html(html + script + css, beautifierConf.html);
      },
      tagChange(newTag) {
        newTag = this.cloneComponent(newTag);
        const config = newTag.__config__;
        newTag.__vModel__ = this.activeData.__vModel__;
        config.formId = this.activeId;
        config.span = this.activeData.__config__.span;
        this.activeData.__config__.tag = config.tag;
        this.activeData.__config__.tagIcon = config.tagIcon;
        this.activeData.__config__.document = config.document;
        if (
          typeof this.activeData.__config__.defaultValue ===
          typeof config.defaultValue
        ) {
          config.defaultValue = this.activeData.__config__.defaultValue;
        }
        Object.keys(newTag).forEach((key) => {
          if (this.activeData[key] !== undefined) {
            newTag[key] = this.activeData[key];
          }
        });
        this.activeData = newTag;
        this.updateDrawingList(newTag, this.drawingList);
      },
      updateDrawingList(newTag, list) {
        const index = list.findIndex(
          (item) => item.__config__.formId === this.activeId
        );
        if (index > -1) {
          list.splice(index, 1, newTag);
        } else {
          list.forEach((item) => {
            if (Array.isArray(item.__config__.children))
              this.updateDrawingList(newTag, item.__config__.children);
          });
        }
      },
      refreshJson(data) {
        this.drawingList = deepClone(data.fields);
        delete data.fields;
        this.formConf = data;
      },
    },
  };
</script>

<style lang="scss">
  @import "@/styles/home";

  .work_wrap {
    width: 100%;
    height: 100%;
    background: #fafafa;

    .nav {
      width: 1200px;
      margin: 0 auto;
      // padding-top: 51px;
    }

    .container {
      width: 1200px;
      margin: 0 auto 100px;
      background: #ffffff;
      // box-shadow: 1px 5px 29px 0px rgba(22, 116, 255, 0.05);
      border-radius: 10px;

      .center-board {
        .center-scrollbar {
          height: calc(100vh - 42px);
          overflow: hidden;
          border-left: 1px solid #f1e8e8;
          border-right: 1px solid #f1e8e8;
          box-sizing: border-box;
        }

        .action-bar {
          border: none;
          display: flex;
          align-items: center;
          height: auto;
          padding: 5px 15px;

          // height: 50px;
          .save {
            width: 138px;
            // height: 37px;
            background: #508ef9;
            // box-shadow: 1px 5px 13px 0px rgba(22, 116, 255, 0.28);
            border-radius: 5px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
            line-height: 37px;
            font-size: 14px;
            margin-right: 20px;
          }
        }
      }
    }
  }
</style>